import * as React from "react"

import { FeatBox } from '../components/FeatBox'
import { Footer } from '../components/Footer'
import { Hero } from '../components/Hero'
import { Testimonial } from '../components/Testimonial'
import { TopNav } from '../components/TopNav'

const Home: React.FC = () => {
  return (
    <>
      <TopNav />
      <Hero />
      <FeatBox />
      <Testimonial />
      <Footer />
    </>
  )
}

export default Home
